import React from 'react';
import { Typography, Paper, Box, Button } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>User Profile</Typography>
        <Typography variant="body1" paragraph>Email: {user.email}</Typography>
        <Typography variant="body1" paragraph>Subscription Plan: {user.plan || 'Not subscribed'}</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          sx={{ mt: 2 }}
          onClick={() => navigate('/subscription')}
        >
          Upgrade Plan
        </Button>
      </Paper>
    </Box>
  );
};

export default UserProfile;